export const environment = {
    production: true,
    staging: false,
    dev: false,

    identity_base_url: 'https://identity.smarttask.io/',
    auth_base_url: 'https://auth-backend.smarttask.io/',
    api_base_url: 'https://api-v2.smarttask.io/',
    api2_base_url: 'https://api2-v2.smarttask.io/',
    ext_base_url: 'https://ext-v2.smarttask.io/',
    app_base_url: 'https://v2.smarttask.io/',
    main_base_url: 'https://www.smarttask.io/',
    form_base_url: 'https://form.smarttask.io/',
    cookie_domain: '.smarttask.io',

    template_organization_id: 25,

    prismic_api_url: 'https://smarttask.cdn.prismic.io/api/v2',
    prismic_access_token: 'MC5aY0h6RHhFQUFDRUFiRDNz.77-9SO-_vWTvv73vv73vv73vv70G77-9CVnvv71CF--_vQEu77-9IA1U77-977-977-9UT3vv71t77-977-977-9',

    recaptcha: {
        client_key: '6Lcz1UApAAAAAFcnfjtHSknE4LLXnV2uAzNd1Xo4',
    },
};
