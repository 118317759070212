import { ActionReducerMap } from '@ngrx/store';
import { OrganizationlistReducers } from '../services/Organization/store/organizationlist.reducers';
import { OrganizationUserlistReducers } from '../services/OrganizationUser/store/organizationUserlist.reducers';
import { GroupSidebarReducers } from '../services/Group/store/groupSidebar.reducers';
import { ProjectSidebarReducers } from '../services/Project/store/projectSidebar.reducers';
import { CustomFieldlistReducers } from '../services/CustomField/store/customFieldlist.reducers';
import { DiscussionlistReducers } from '../services/Discussion/store/discussionlist.reducers';
import { DiscussionActivitylistReducers } from '../services/DiscussionActivity/store/discussionActivitylist.reducers';
import { ViewlistReducers } from '../services/View/store/viewlist.reducers';
import { TasklistReducers } from '../services/Task/store/tasklist/tasklist.reducers';
import { ChatChannellistReducers } from '../services/Chat/store/channel/channellist.reducers';
import { ChatMessagelistReducers } from '../services/Chat/store/message/messagelist.reducers';
import { CallUserConfigReducers } from '../services/CRM/CallConfig/store/config.reducers';
import { CalllistReducers } from '../services/CRM/Call/store/call.reducers';
import { ContactReducers } from '../services/CRM/Contact/store/contact.reducers';
import { ContactViewReducers } from '../services/CRM/View/store/contactView.reducers';
import { FileViewReducers } from '../services/File/store/fileView.reducers';
import { SessionlistReducers } from '../services/Session/store/sessionlist.reducers';
import { PortfoliolistReducers } from '../services/Portfolio/store/portfolio.reducers';
import { PortfolioProjectReducers } from '../services/PortfolioProject/store/portfolioProject.reducers';
import { recordActivityReducers } from '../services/Record/store/recordActivity.reducers';
import { ReportReducers } from '../services/Report/Core/store/report.reducers';
import { ReportCardReducers } from '../services/Report/Card/store/card.reducers';
import { TaskQueueReducers } from '../services/Task/store/queue/taskQueue.reducers';
import { TimelogReducers } from '../services/TimeTracking/store/timelog.reducers';
import { NotificationReducers } from '../services/Notification/store/notification.reducers';
import { OrganizationJobRoleReducers } from '../services/Resource/jobRole/store/organizationJobRole.reducers';
import { HolidayReducers } from '../services/Resource/holiday/store/organizationHoliday.reducers';
import { OutOfOfficeReducers } from '../services/Resource/out-of-office/store/outOfOffice.reducers';

export const reducers: ActionReducerMap<any> = {
    calllist: CalllistReducers,
    callUserConfig: CallUserConfigReducers,

    contactlist: ContactReducers,
    contactView: ContactViewReducers,

    chatChannellist: ChatChannellistReducers,
    chatMessagelist: ChatMessagelistReducers,

    customFieldlist: CustomFieldlistReducers,

    discussionlist: DiscussionlistReducers,
    discussionActivitylist: DiscussionActivitylistReducers,

    fileView: FileViewReducers,

    groupSidebar: GroupSidebarReducers,

    holiday: HolidayReducers,

    notification: NotificationReducers,

    portfoliolist: PortfoliolistReducers,
    portfolioProject: PortfolioProjectReducers,

    projectSidebar: ProjectSidebarReducers,

    organizationlist: OrganizationlistReducers,
    // sessionlist: SessionlistReducers,
    organizationUserlist: OrganizationUserlistReducers,
    organizationJobRole: OrganizationJobRoleReducers,
    ooo: OutOfOfficeReducers,

    recordActivity: recordActivityReducers,

    report: ReportReducers,
    reportCard: ReportCardReducers,

    sessionlist: SessionlistReducers,

    tasklist: TasklistReducers,
    taskQueue: TaskQueueReducers,

    timelog: TimelogReducers,

    viewlist: ViewlistReducers,
};
