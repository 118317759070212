import { CommonModule } from "@angular/common";
import { Component, ChangeDetectionStrategy } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { NgbActiveModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CommonDirectivesModule } from "@smarttask-common/src/lib/directives/directives.module";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";

@Component({
    selector: 'st-payment-success-modal',
    templateUrl: `./successModal.html`,
    styleUrls: ['./successModal.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormsModule, NgbModule, NgxSkeletonLoaderModule, CommonDirectivesModule],
})
export class PaymentSuccessModal{

    constructor(
        public activeModal: NgbActiveModal
    ){
        
    }
}