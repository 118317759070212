
<div class="modal-header">
    <!-- <h4 class="modal-title">Plan Details</h4> -->
    <button type="button" class="btn-close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <div class="st-img-wrap d-flex align-items-center justify-content-center mb-3">
        <img src="assets/images/app/payment/success-illustration.png" />
    </div>
    <h5 class="st-title d-flex justify-content-center">
        Subscription Activated
    </h5>
    <div class="st-subtitle st-text-grey d-flex justify-content-center mb-5">
        Your subscription has been activated successfully.
    </div>
    <div class="st-actions d-flex align-items-center justify-content-center">
        <button class="st-btn st-btn-primary"
            (click)="activeModal.close()">
            Continue
        </button>
    </div>
</div>